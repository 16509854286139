import {validateEmail} from './form-helpers';


window.contactViewHandler = (mailinSubmitUrl, backUrl) => {
  window.goBack = () => {
    window.location = backUrl;
  };

  $(document).ready(() => {
    $(document).on('click', '.jsGoBack', goBack);
  });

  // Modyfikacja url po przejściu z hp, gdy wypełniliśmy form
  let getUrlParameter = function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  if (getUrlParameter('email')) {
    document.getElementById('email').value = getUrlParameter('email');
  }
  history.replaceState('dolacz', 'Na co idą moje pieniądze', '/dolacz');

  $(window).on('load', () => {
    function validatePhone(data) {
      let re = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      return re.test(String(data));
    }

    function sendData() {
      let fields = {'fields': $('#dolacz').serializeArray()};

      // założenie - jeżeli wartość podana w polu email / telefon nie jest emailem, wartość zostaje wysłana jako pole phone
      // założenie 2 - jeżeli wartość z pola email / telefon nie spełnia żadnej z walidacji, dodajemy ją do wartości message
      let emailFieldValue = fields.fields.filter(function (item) {
        return item.name === 'email';
      })[0].value;
      let isEmail = validateEmail(emailFieldValue);
      let isPhone = validatePhone(emailFieldValue);
      if (!(isEmail) && isPhone) {
        fields.fields.filter(function (item) {
          return item.name === 'email';
        })[0].name = 'phone';
      } else {
        fields.fields.filter(function (item) {
          return item.name === 'message';
        })[0].value += (' ' + emailFieldValue);
        // TODO czy układ formularza zapewnia stałe indeksy i takie usuwanie jest bezpieczne ?
        fields.fields.splice(2, 1);
      }
      $.ajax({
        url: mailinSubmitUrl,
        data: JSON.stringify(fields),
        type: 'POST',
        contentType: 'application/json',
        error: function (jqXHR, error, errorThrown) {
          $('.contact-form-submit').addClass('has-error');
        },
        success: function (data, textStatus, jqXHR) {
          $('.container-summary').removeClass('hidden');
          $('.contact--container').remove();
          history.replaceState('dolacz', 'Na co idą moje pieniądze', '/dolacz/dziekujemy');
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
        }
      });
    }


    window.validateForm = () => {
      let errors = 0;
      let formElement = $('#dolacz').find('input');
      let contactArray = [];
      let termsChecked = document.getElementById('check').checked;
      for (let i = 0; i < formElement.length; i++) {
        let elementId = $(formElement[i]).attr('id');
        if (((elementId === 'check' && !(termsChecked)) || (elementId !== 'check' && $(formElement[i]).val() === '')) && elementId !== 'message') {
          $(formElement[i]).parent().addClass('has-error');
          $('.contact-form-require-error').addClass('has-error');
          $('.contact-form-group.has-error') ? errors++ : '';
        } else {
          let formInput = $(formElement[i]).val();
          contactArray.push(formInput);
          $(formElement[i]).parent().removeClass('has-error');
        }
      }
      ($('#check').prop('checked')) ? ($('.contact-form-terms')).removeClass('has-error') : ($('.contact-form-terms')).addClass('has-error');
      if (errors < 1) {
        $('.contact-form-submit').removeClass('has-error');
        $('.contact-form-require-error').removeClass('has-error');
        $('.contact-summary--city').html(contactArray[0]);
        $('.contact-summary--name').html(contactArray[1]);
        $('.contact-summary--email').html(contactArray[2]);
        $('.contact-summary--msg').html(contactArray[3] || 'Brak');
        sendData();
      }
    };

    $('#sendForm').on('click', function (event) {
      event.preventDefault();
      validateForm();
    });
  });
};
