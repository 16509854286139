import PerfectScrollbar from 'perfect-scrollbar';

class SearchBox {
  constructor(searchBoxSelector, targetInputSelector, selectedValue = '') {
    this.el = document.querySelector(searchBoxSelector);
    this.targetInput = document.querySelector(targetInputSelector);
    this.ui = {};
    this.inputValue = '';
    this.selectedValue = selectedValue;
    this.dataList = [];
    this.filteredResults = [];

    this.initialize();
  }

  initialize() {
    this.initUI();
    this.initData();
    this.initEvens();
  }

  initUI() {
    const uiElements = [
      'search_box_selection',
      'search_box_back',
      'city_search_input',
      'input_text',
      'first_hint',
      'search_box_results',
    ];
    uiElements.forEach(element => {
      this.ui[element] = this.el.querySelector('#' + element);
    });
  }

  initData() {
    const dataList = document.querySelector(this.ui.city_search_input.dataset.list);
    if (dataList && dataList.options) {
      this.dataList = [].slice.call(dataList.options).map(option => option.value);
    }
    this.filteredResults = this.dataList.slice(0);
    this.renderList();
    this.setSelection(this.selectedValue);
  }

  initEvens() {
    this.ui.search_box_selection.addEventListener('click', () => {
      this.showDropdown();
    });
    this.ui.city_search_input.addEventListener('input', () => {
      this.processInput();
    });
    this.ui.city_search_input.addEventListener('keydown', event => {
      if (event.keyCode === 9 && !!this.ui.first_hint.innerText) {
        event.preventDefault();
        this.selectFirstHint();
      }
      if (event.keyCode === 13) {
        if (this.dataList.includes(this.inputValue)) {
          event.preventDefault();
          this.setSelection(this.inputValue);
          this.hideDropdown();
        } else if (!!this.ui.first_hint.innerText) {
          event.preventDefault();
          this.selectFirstHint();
        }
      }
    });
    this.ui.search_box_results.addEventListener('click', event => {
      for (let target = event.target; target && target !== this; target = target.parentNode) {
        if (target.matches('.result-item')) {
          this.processSelection(target);
          break;
        }
      }
    }, false);
    this.ui.search_box_back.addEventListener('click', event => {
      this.hideDropdown();
    });
  }

  showDropdown() {
    this.ui.search_box_selection.classList.add('show-dropdown');
    this.updateDropdownView();
  }

  hideDropdown() {
    this.ui.search_box_selection.classList.remove('show-dropdown');
    this.clearDropDownValues();
  }

  clearDropDownValues() {
    this.setInputValue('');
  }

  setInputValue(value) {
    this.ui.city_search_input.value = value;
    this.ui.city_search_input.dispatchEvent(new Event('input', { bubbles: true }));
  }

  processInput() {
    this.inputValue = this.ui.city_search_input.value;
    this.filterList();
    this.updateDropdownView();
  }

  renderHintSuffix() {
    let suffix = '';
    if (!!this.inputValue && this.filteredResults.length)
      suffix = this.filteredResults[0].slice(this.inputValue.length);

    this.ui.first_hint.innerText = suffix;
  }

  selectFirstHint() {
    if (!!this.inputValue && this.filteredResults.length)
      this.setInputValue(this.filteredResults[0]);
  }

  processSelection(target) {
    this.setSelection(target.dataset.value);
    this.hideDropdown();
  }

  setSelection(value) {
    this.selectedValue = value;
    this.targetInput.value = this.selectedValue;
    this.ui.search_box_selection.innerText = this.selectedValue || this.ui.search_box_selection.dataset.placeholder;
  }

  filterList() {
    if (!this.inputValue.length) {
      this.filteredResults = this.dataList;
    } else {
      const re = new RegExp('^' + this.inputValue, 'i');
      this.filteredResults = this.dataList.reduce((carry, name) => {
        if (re.test(name))
          carry.push(name);
        return carry;
      }, []);
    }
  }

  updateDropdownView() {
    this.ui.input_text.innerText = this.inputValue;
    this.renderHintSuffix();
    this.ui.city_search_input.style.width = (this.ui.input_text.getBoundingClientRect().width || 2) + 'px';
    this.renderList();
  }

  renderList() {
    this.ui.search_box_results.innerHTML = '';
    const len = this.inputValue.length;
    this.filteredResults.forEach(result => {
      const option = `<li class="result-item" data-value="${result}"><span class="match">${result.slice(0, len)}</span>${result.slice(len)}</li>`;
      this.ui.search_box_results.insertAdjacentHTML('beforeend', option);
    });

    new PerfectScrollbar(this.ui.search_box_results, {
      suppressScrollX: true,
      wheelPropagation: false,
    });
  }
}

window.cityListViewHandler = () => {
  const searchParams = new URLSearchParams(location.search);
  new SearchBox('#search_box', '[name="miejscowosc"]', searchParams.get('miejscowosc') || '');

  const sortDropdown = document.querySelector('.sort-dropdown');
  const dropdownHandler = sortDropdown.querySelector('.current-selection');
  dropdownHandler.addEventListener('click', () => sortDropdown.classList.toggle('active'));
};
