import '../vendors/cssmapsplugin/jquery.cssmap.min';
import PerfectScrollbar from 'perfect-scrollbar';


window.indexViewHandler = () => {
  let psList = [];
  let mapActivated = false;

  const listClick = function(listItem) {
    let href = $(listItem.find('a'));
    let listId = href.attr('href');
    let listIdElement = $(listId);
    if (!listIdElement.hasClass('clicked')) {
      $(listId).trigger('click');
      refreshScrolls();
    }
  };

  const refreshScrolls = () => {
    psList.forEach((ps) => {
      ps.update();
    });
  };

  $(document).ready(() => {
    let allListRegion = $('.list-of-city');
    for (let i = 0; i < allListRegion.length; i++) {
      psList.push(
        new PerfectScrollbar(allListRegion[i], {
          suppressScrollX: true,
          wheelPropagation: false
        })
      )
    }
  });

  $(window).on('load', () => {
    // CSSMap
    $('#map-poland').CSSMap({
      'size': 540,
      'mapStyle': 'custom',
      'tooltips': 'visible',
      'tapOnce': true,
      'mobileSupport': true,
      'visibleList': {
        'enable': true,
        'listPosition': 'left',
        'columns': 1,
        'columnsGap': 216,
        'columnWidth': 334
      },
      'agentsList': {
        'enable': true,
        'agentsListId': '#list-of-cities',
        'agentsListSpeed': 300,
        'agentsListOnHover': false
      },
      onClick: listClick,
      onLoad: function() {
        if (mapActivated === true) return;
        // activateOnLoad nie działa dobrze
        $('li.pl7 .m').trigger('click');
        mapActivated = true;
        refreshScrolls();
      }
    });

    // Blokowanie wyświetlania na mapie listy miast = 0
    let region = $('.country-map .count');
    for (let i = 0; i < region.length; i++) {
      (region[ i ].outerText === 0) ? $(region[i]).parent().css('display', 'none') : '';
    }

    // Rozwijanie listy miast w danym regionie
    $('.list-of-region__item').click(function () {
      let allRegion = $('.list-of-region__item');
      for (let i = 0; i < allRegion.length; i++) {
        if ($(allRegion[ i ]).hasClass('clicked')) {
          if ($(allRegion[i]).css('display') === 'none')
            $(allRegion[ i ]).removeClass('clicked');
        }
      }
      $(this).toggleClass('clicked');
    });
  });
};
