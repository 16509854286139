import '@babel/polyfill';
import PerfectScrollbar from 'perfect-scrollbar';
import isMobile from 'ismobilejs';
import './index';
import './budget';
import './contact';
import './city-list';
import 'bootstrap';
import 'jquery.scrollto';

window.getScrollContainer = () => {
  if (isMobile.any)
    return $(window);
  return $('#container');
};

window.spinner = (see) => {
  (see === true) ? $('.spinner').removeClass('visibility') : $('.spinner').addClass('visibility');
};

window.isIE = () => {
  return (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf('Trident/') > -1);
};

window.getCookie = function(c_name) {
  let c_end, c_start;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};
window.setCookie = function(c_name, value, exdays) {
  let c_value, exdate;
  exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  c_value = escape(value) + '; expires=' + exdate.toUTCString();
  return document.cookie = c_name + '=' + c_value + ';path=/';
};

window.coreHandler = () => {
  // Wyszukiwarka
  let cityDefList, searchResult, limit = 12, ajax_counter = 0, tags = [];
  window.pageLoad = true;
  window.pageHasMore = true;
  window.page = 0;

  if (isIE()) {
    $('body').addClass('msie');
  }
  // zarządzanie stanem spinnera
  const fixImageRatio = () => {
    setTimeout(() => {
      $('body').find('.fixImageRatio').each(function (index) {
        let $container, containerHeight, containerWidth, divRatio, heightImg, imageRatio, widthImg;
        $container = $(this).parent();
        containerWidth = $container.width();
        containerHeight = $container.height();
        widthImg = $(this).width();
        heightImg = $(this).height();
        imageRatio = widthImg / heightImg;
        divRatio = containerWidth / containerHeight;
        // if (!!$(this).parents('#fund-solecki').length) {
        //   debugger;
        // }
        if (imageRatio <= divRatio) {
          return $(this).css({
            width: '100%',
            height: 'auto'
          });
        } else {
          return $(this).css({
            width: 'auto',
            height: '100%'
          });
        }
      });
    }, 500);
  };
  $(document).ready(function () {
    fixImageRatio();
  });


  // wyszukiwarka ajax
  window.sendSearch = (thisObj, resultName, page, tags, pageLoad) => {
    spinner(true);
    ajax_counter++;
    thisObj = thisObj ? $(thisObj).val() : '';
    // określenie pozycji wstrzyknięcia wyników
    (resultName === 'boxCitySearch') ? searchResult = $('.box-city-default') : '';
    (resultName === 'navListCity') ? searchResult = $('.nav-list-city') : '';
    if (resultName === 'boxCityDefault') {
      searchResult = $('.box-city-default');
      limit = 6;
    }
    (page === 0) ? searchResult.empty() : '';
    let data = { name: thisObj, position: resultName, counter: ajax_counter, page: page, limit: limit };
    // Jeśli tagi istnieją..
    if (tags) {
      for (let i = 0; i < tags.length; i++) {
        (tags[ i ] === 'has-incomes-or-expenses') ? Object.assign(data, { 'has-incomes-or-expenses': 'has-incomes-or-expenses' }) : '';
        (tags[ i ] === 'has-investment-tasks') ? Object.assign(data, { 'has-investment-tasks': 1 }) : '';
        (tags[ i ] === 'has-participation-budget') ? Object.assign(data, { 'has-participation-budget': 1 }) : '';
        (tags[ i ] === 'has-village-fund') ? Object.assign(data, { 'has-village-fund': 1 }) : '';
        (tags[ i ] === 'has-pit-forecasts') ? Object.assign(data, { 'has-pit-forecasts': 1 }) : '';
      }
    }
    $.ajax({
      method: 'GET',
      url: '/budzet-miasta-filtruj',
      dataType: 'json',
      data: data
    }).fail(function () {
      console.log('Wystąpił błąd w połączeniu');
    })
      .done(function (msg) {
        if (ajax_counter === msg.counter) {    //Sprawdzenie, czy odpowiedź jest najnowsza
          if (page > 0) {    // Sprawdzenie, czy to jest doładowanie
            if (msg.template !== '\n') {   // Jeśli to jest doładowanie, to czy jest co doładowywać.
              searchResult = searchResult + searchResult.append($(msg.template));
              window.pageLoad = true;
              window.pageHasMore = msg.has_more_objects;
            }
          } else { // Jeśli to jest nowy wynik szukania...
            searchResult.empty();   // ... wyczyść listę wyników na stronie
            if (msg.template === '\n') {   // Jeśli wynik szukania jest pusty...
              searchResult.append('<p class="nav-list-city--failure">Brak miasta</p>'); // ... Wyświetl komunikat ...
              $('.list-cities').addClass('whiteBG'); // ... i usuń szare tło
            } else {
              $('.list-cities').removeClass('whiteBG'); // ... lub przywróć szare tło
              searchResult.append($(msg.template)); // ... wyświetl wynik
            }
            window.pageHasMore = msg.has_more_objects;
          }
          spinner(false);
        }
      });
  };

  // Zczytanie aktywnych tagów
  window.activeTags = () => {
    tags = [];
    let activeTags = $('.tags .tags--tag.active');
    for (let i = 0; i < activeTags.length; i++) {
      let el = activeTags[ i ].getAttribute('data-tag-filter');
      tags.push(el);
    }
    return tags;
  };

  // Reakcja na wpisywanie w nawigacji
  $('body').on('keyup', 'nav input', function (event) {
    event.stopPropagation();
    sendSearch($(this), 'navListCity');
  });

  // Zapobieganie przeładowania strony po wciśnięciu Enter w polu wyszukiwarki
  $('body').on('keypress', 'input', function (event) {
    event.stopPropagation();
    (event.which === 13) ? event.preventDefault() : '';
  });
  const focusInputSearch = () => {
    if (!$('#navbar-city').hasClass('show')) {
      setTimeout(function () {
        $('#inputCitySearch').trigger('click');
      });
    }
  };
  $('#inputCitySearch').on('focus', (e) => {
    $(this).select();
  });
  const navbarDropdown = $('#navbarDropdown');
  $(document).on('click', navbarDropdown, (event) => {
    focusInputSearch(event);
  });
  $(document).on('touchstart', navbarDropdown, function (event) {
    focusInputSearch(event);
  });


  // Odczytywanie stanu aktywnego taga
  $('.tags--tag').click(function () {
    window.page = 0;
    window.pageLoad = true;
    ($('.tags .tags--tag').parent()[ 0 ].id === 'hp-tags') ? cityDefList = 'boxCityDefault' : '';
    ($('.tags .tags--tag').parent()[ 0 ].id === 'search-tags') ? cityDefList = 'boxCitySearch' : '';
    $(this).toggleClass('active');
    let tags = activeTags();
    sendSearch($(this), cityDefList, window.page, tags);
    //Powraca na górę strony podczas wybierania tagów
    if (cityDefList === 'boxCitySearch') getScrollContainer().scrollTo(0, 100);
  });

  // inicjalizacja scrolla dla desktop
  $(document).ready(() => {
    let taxesModalWrapper = $('.taxes-modal__scroll-container');
    if (Boolean(taxesModalWrapper.length)) {
      new PerfectScrollbar(taxesModalWrapper[ 0 ], {
        suppressScrollX: true
      });
    }
    if (isMobile.any === false) {
      let ps = new PerfectScrollbar('#container');
      ps.update();
      if ($('.nav-list-city')) {
        new PerfectScrollbar('.nav-list-city', {
          suppressScrollX: true
        });
      }
      let allModal = $('.taxes-modal__container');
      for (let i = 0; i < allModal.length; i++) {
        new PerfectScrollbar(allModal[ i ], {
          suppressScrollX: true
        });
      }
      $('body').addClass('notmobile');
    } else {
      $('body').removeClass('notmobile');
    }
  });

  $('#navbar-city').on('show.bs.dropdown', function () {
    $('.city-button').addClass('showit');
  });

  $('#navbar-city').on('hidden.bs.dropdown', function () {
    $('.city-button').removeClass('showit');
  });

  $(window).on('load', () => {
    // Obsługa cookies
    let closeCookie, cookie, website_cookie;
    closeCookie = $('.cookies-box__close');
    closeCookie.on('click', function () {
      $('.cookies-box').fadeOut();
      return setCookie('website_cookies', 1, 365 * 10);
    });
    website_cookie = getCookie('website_cookies');
    if (website_cookie.length === 0) {
      $('.cookies-box').show();
    } else {
      $('.cookies-box').hide();
    }

    // popover
    if ($('#container').length > 0) {
      $('[data-toggle="popover"]').popover({
        html: true,
        placement: 'top',
        trigger: 'focus'
      });
    }
  });
};


$(window).on('load', function () {
  const container = document.querySelector('#container');
  if (!isIE()) {
    container.addEventListener('ps-scroll-y', () =>
      StickyTemporary()
    );
  }

  function StickyTemporary() {
    if ($('#nav-budget').length > 0) {
      let navHeader = document.getElementById('nav');
      let navHeight = $(navHeader).outerHeight();
      let navBudget = document.getElementById('nav-budget');
      let sticky = $(navBudget).offset().top;
      let cityButton = $('.city-button');
      let navbarBrand = $('.navbar-brand');
      let navbarCity = $('#navbar-city');
      let navPos = navHeight - sticky;
      if (navHeight > sticky) {
        $(navHeader).css({ 'top': -1 * navPos + 'px', 'transition': 'width ' + 0 + 's' });
        if (navbarCity.hasClass('show')) {
          $(cityButton).css({ 'top': 'inherit' });
          $(navbarBrand).css({ 'top': navPos + 'px', 'position': 'relative' });
        } else {
          $(cityButton).css({ 'top': navPos + 'px' });
        }
      } else {
        $(navHeader).css({ 'top': 0 + 'px' });
        if (navbarCity.hasClass('show')) {
          $(cityButton).css({ 'top': 'inherit' });
          $(navbarBrand).css({ 'top': 'inherit', 'position': 'static' });
        } else {
          $(cityButton).css({ 'top': 0 + 'px' });
        }
      }
      navbarCity.on('hide.bs.dropdown', function () {
        if (navHeight > sticky) {
          $(cityButton).css({ 'top': navPos + 'px' });
        } else {
          $(cityButton).css({ 'top': '0' });
        }
        $(navbarBrand).css({ 'top': 'inherit', 'position': 'static' });
      });
    }
  }

  if (isMobile.any) {
    // fix dziwnego zachowania na iPhone
    $('.menu-nav .nav-list-city--item, .menu-nav .city-button a').on('click', (event) => {
      $('.menu-nav .dropdown-toggle').dropdown('toggle');
      setTimeout(() => { window.location = $(event.target).attr('href');}, 300);
      event.preventDefault();
    });
  }
});
