import {GoogleCharts} from 'google-charts';
import isMobile from 'ismobilejs';
import $ from 'jquery';
import 'slick-carousel';
import SalaryParser from './salary';

window.loadChart = (budgerColors, budgetIncomes) => {
  GoogleCharts.load(drawChart);

  function drawChart() {
    const colors = JSON.parse(budgerColors);
    const colorWihtoutContrast = {};
    Object.assign(colorWihtoutContrast, colors);
    delete colorWihtoutContrast['color_contrast'];
    const percentages = JSON.parse(budgetIncomes)['values'];
    const data = new GoogleCharts.api.visualization.DataTable();
    data.addColumn('string');
    data.addColumn('number');
    data.addColumn({'type': 'string', 'role': 'tooltip', 'p': {'html': true}});
    const slices = {};
    percentages.forEach((el, index) => {
      switch (index % Object.keys(colorWihtoutContrast).length) {
        case 0:
          slices[index] = {color: colorWihtoutContrast['color_brand_1']};
          break;
        case 1:
          slices[index] = {color: colorWihtoutContrast['color_brand_2']};
          break;
        case 2:
          slices[index] = {color: colorWihtoutContrast['color_p2']};
          break;
        case 3:
          slices[index] = {color: colorWihtoutContrast['color_d3']};
          break;
        case 4:
          slices[index] = {color: colorWihtoutContrast['color_d4']};
          break;
      }
      data.addRows([
        [
          ...el,
          createCustomHtmlTooltip(el),
        ],
      ]);
    });

    const formatter = new GoogleCharts.api.visualization.NumberFormat({
      fractionDigits: 2,
      decimalSymbol: ',',
    });
    formatter.format(data, 1);
    const options = {
      allowHtml: true,
      pieHole: 0.70,
      slices: slices,
      height: isMobile.phone ? 380 : 400,
      tooltip: {isHtml: true, trigger: isMobile.phone ? 'selection' : 'none'},
      ariaLabel: 'Wykres kołowy przedstawiający strukturę dochodów miejscowości',
      pieSliceText: 'none',
      legend: {
        position: isMobile.phone ? 'none' : 'labeled',
        textStyle: {
          color: window.isHighContrastMode ? '#fff' : '#000',
          bold: true,
        },
      },
      chartArea: {
        left: 5,
        width: (isMobile.phone ? '100%' : '90%'),
        height: '80%',
      },
      backgroundColor: 'transparent',
      sliceVisibilityThreshold: 0,
    };

    const chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('chart-area'));
    chart.draw(data, options);
  }
};

function createCustomHtmlTooltip(el) {
  return '<div class="chart_tooltip">' + '<span class="tooltip_title">' + el[1].toFixed(2) + ' %' + '</span>'
    + '<span class="tooltip_budget">' + '% budżetu' + '</span>' + '<br>' + el[0] + '</div>';
}

const changeProgress = (thisObj) => {
  const progressChange = thisObj.each(function() {
    $('.type-of-budget').children().removeClass('active');
    $(this).addClass('active');
  });
  return progressChange.attr('data-type');
};

const changeDropdown = (thisObj) => {
  const dropChange = thisObj.each(function() {
    $('#dropdownTypeTopic')[0].textContent = $(this)[0].textContent;
    $('.dropdown-topic-list').children().removeClass('active');
    $(this).addClass('active');
  });
  return dropChange.attr('data-change');
};

const changeOnSlide = (thisObj) => {
  const classes = ['.info-type--cost', '.info-type__slider--see-more', '.info-type__slider'];
  const dataHtml = ['data-value', 'data-modal', 'data-slide'];
  for (let i = 0; i < classes.length; i++) {
    $(classes[i]).children().each(function() {
      const typeOfValue = $(this).attr(dataHtml[i]);
      $(this).removeClass('active');
      if (typeOfValue === thisObj) {
        $(this).addClass('active');
      }
    });
  }
  $(`[data-slide=${thisObj}]`).slick('slickGoTo', 0, true);
};

const initSliderPauseButton = ($slider) => {
  const $button = $('<button class="slick-arrow slick-pause"><span class="pause"></span><span class="play"></span><span class="sr-only">Zatrzymaj/wznów odtwarzanie galerii</span></button>');
  $button.on('click', () => {
    if ($slider.slick('getSlick').paused) {
      $slider.slick('slickPlay');
      $button.removeClass('paused');
    } else {
      $slider.slick('slickPause');
      $button.addClass('paused');
    }
  });
  $slider.find('.slick-prev').before($button);
};

const initSliderCounter = ($slider, counterClassName) => {
  $slider.find('.slick-pause').before($('<div class="slide__box slide__box--counter counter"><span class="' + counterClassName + '"></span></div>'));
};

$('body').on('click', (e) => {
  if ($(e.target).parents('.taxes-modal-slider-bottom-group').hasClass('active')) {
    $(e.target).parents('.taxes-modal-slider-bottom-group').removeClass('active');
  }
});

window.budgetInt = () => {
  // moduł 4 - Zadania inwestycyjne
  $('.type-of-budget').children().on('click', function() {
    const topicChange = changeProgress($(this));
    $('.dropdown-topic-list').children().each(function() {
      const typeOfChange = $(this).attr('data-change');
      if (typeOfChange === topicChange) {
        changeDropdown($(this));
      }
    });
    changeOnSlide(topicChange);
  });

  $('.dropdown-topic-list').children().on('click', function() {
    const dataChange = changeDropdown($(this));
    $('.type-of-budget').children().each(function() {
      const typeOfChange = $(this).attr('data-type');
      if (typeOfChange === dataChange) {
        changeProgress($(this));
      }
    });
    changeOnSlide(dataChange);
  });

  $('.info-type__slider-info').each((i, slider) => {
    const $slider = $(slider);
    $slider.on('init reInit breakpoint', () => {
      initSliderPauseButton($slider);
    }).slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: false,
      prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzedni slajd"></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Następny slajd"></button>',
      responsive: [{
        breakpoint: 1199,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      }, {
        breakpoint: 767,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      }],
    });
  });

  // Slider moduł 5, 6 - Budżet obywatelski, Fundusz sołecki
  const responsiveOptions = [{
    breakpoint: 1199, // do desktop - 1 (widok na tablet)
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      fade: false,
    },
  }, {
    breakpoint: 767, // do tabletu - 1 (widok na mobile)
    settings: {
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }];

  const $citizensBudgetSlider = $('.slider-img--city-citizens');
  $citizensBudgetSlider.on('init reInit breakpoint', () => {
    initSliderPauseButton($citizensBudgetSlider);
    initSliderCounter($citizensBudgetSlider, 'city-citizens-counter');
  }).slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    adaptiveHeight: false,
    asNavFor: '.slider-content--city-citizens',
    prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzedni slajd"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Następny slajd"></button>',
    responsive: responsiveOptions,
  });

  $('.slider-content--city-citizens').on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
    const citizenCounter = $('.city-citizens-counter');
    const i = (currentSlide ? currentSlide : 0) + 1;
    const slidesToShow = slick.slickGetOption('slidesToShow');
    citizenCounter.text(Math.round(i / slidesToShow) + '/' + Math.round(slick.slideCount / slidesToShow));
    const curentProgress = $('.slider-content--city-citizens .slick-slide.slick-current.slick-active .progress-bar');
    curentProgress.css({'width': 0 + '%', 'transition': 'width ' + 0 + 's linear'});
    curentProgress.css({'width': 100 + '%', 'transition': 'width ' + 5 + 's linear'});
  }).slick({
    dots: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: false,
    fade: true,
    asNavFor: '.slider-img--city-citizens',
    responsive: responsiveOptions,
  });

  const $fundSoleckiSlider = $('.slider-img--fund-solecki');
  $fundSoleckiSlider.on('init reInit breakpoint', () => {
    initSliderPauseButton($fundSoleckiSlider);
    initSliderCounter($fundSoleckiSlider, 'found-solecki-counter');
  }).slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: true,
    adaptiveHeight: false,
    asNavFor: '.slider-content--fund-solecki',
    prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzedni slajd"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Następny slajd"></button>',
    responsive: responsiveOptions,
  });

  $('.slider-content--fund-solecki').on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
    const fundCounter = $('.found-solecki-counter');
    const i = (currentSlide ? currentSlide : 0) + 1;
    const slidesToShow = slick.slickGetOption('slidesToShow');
    fundCounter.text(Math.round(i / slidesToShow) + '/' + Math.round(slick.slideCount / slidesToShow));
    const curentProgress = $('.slider-content--fund-solecki .slick-slide.slick-current.slick-active .progress-bar');
    curentProgress.css({'width': 0 + '%', 'transition': 'width ' + 0 + 's linear'});
    curentProgress.css({'width': 100 + '%', 'transition': 'width ' + 5 + 's linear'});
  }).slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    fade: true,
    speed: 300,
    asNavFor: '.slider-img--fund-solecki',
    responsive: responsiveOptions,
  });

  const left = $('.progress-percent__left').data('value');
  const right = $('.progress-percent__right').data('value');
  if (!isMobile.any && ($(window).width() >= 1200)) {
    $('.progress-percent__left').css('width', left + '%');
    $('.progress-percent__right').css('width', right + '%');
  } else {
    $('.progress-percent__left').css('height', left + 'vh');
    $('.progress-percent__right').css('height', right + 'vh');
  }

  $(document).ready(() => {
    // Ukrycie po załadowaniu
    const moveOn = $('.moveOn');
    const moveOnOnce = $('.moveOnOnce');
    moveOn.addClass('hide');
    moveOnOnce.addClass('hide');
    // wybranie elementu do scrollowania w zależności od przeglądarki: mobile/desktop
    const scrollContainer = getScrollContainer();

    $(scrollContainer).scroll(function() {
      moveOn.each(function() {
        const el = (moveOn.parent().height());
        if (moveOn.offset().top <= $(document).height() && moveOn.offset().top > 0 + el) {
          $(this).removeClass('hide');
          $(this).children().addClass('showing');
        }
        if (moveOn.offset().top + el <= 0 || moveOn.offset().top >= $(document).height()) {
          if (!$(this).hasClass('moveOnOnce')) {
            $(this).addClass('hide');
            $(this).children().removeClass('showing');
          }
        }
      });
    });

    // przejscie do hasha z url
    $(window).on('load', () => {
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => scrollContainer.scrollTo($(hash)), 250);
      }
    });
    //
  });
};

export const handleTaxRangeSlider = (taxBoxes) => {
  let eventInput;
  try {
    eventInput = new Event('input', {
      'bubbles': true,
      'cancelable': true,
    });
  } catch (error) {
    // IE support
    eventInput = document.createEvent('Event');
    eventInput.initEvent('input', true, true);
  }
  const sliderInput = document.getElementById('taxesInput');
  const sliderOutput = document.getElementById('taxesOutput');
  const taxesDropdown = document.getElementById('taxesDropdown');
  const taxesDropdownContainer = document.getElementById('taxesDropdownContainer');
  const inputDropdownTogler = document.getElementById('taxRangeText');
  const advancedOptionsContainer = document.getElementById('advancedOptions');

  const advancedParams = {
    under26: false,
    pensioner: false,
  };

  const salaryParser = new SalaryParser(window.currentBudgetYear);

  const updateTaxSlider = () => {
    const maxVal = JSON.parse(sliderInput.max);
    const minVal = JSON.parse(sliderInput.min);
    const totalWidth = sliderInput.parentElement.clientWidth;
    const outputWidth = sliderOutput.getBoundingClientRect().width;
    const textPixelOffset = (totalWidth * ((sliderInput.value - minVal) / (maxVal - minVal))) - (outputWidth *
      ((sliderInput.value - minVal) / (maxVal - minVal)));
    sliderOutput.innerText = sliderInput.value;
    sliderOutput.style.left = textPixelOffset + 'px';

    const summaryPerMonthElement = document.getElementById('wholeAmountPerMonth');
    const summaryPerMonthElementModal = document.getElementById('wholeAmountPerMonthModal');
    const summaryPerMonth = setAmountPerMonth(summaryPerMonthElement, sliderInput.value, summaryPerMonthElement.dataset.taxType);
    setAmountPerMonth(summaryPerMonthElementModal, sliderInput.value, summaryPerMonthElement.dataset.taxType);


    for (const taxSingleBox of taxBoxes) {
      const amountEl = taxSingleBox.querySelector('.tax-small-box__amount');
      setAmountPerMonth(amountEl, summaryPerMonth);
    }
    const modalElementsToCalculate = document.querySelectorAll('.jsModalSliderVal');
    for (const modalSingleElToCalc of modalElementsToCalculate) {
      setAmountPerMonth(modalSingleElToCalc, summaryPerMonth);
    }
  };

  const updataInputMaxMin = (min, max, text, inputEl, dropdownLabel) => {
    const value = min + ((max - min) / 2);
    dropdownLabel.innerHTML = text + '<i class="togler"></i>';
    inputEl.min = min;
    inputEl.max = max;
    inputEl.value = value;
    sliderInput.dispatchEvent(eventInput);
  };

  const setAmountPerMonth = (element, currentSalary, dataType) => {
    if (element === null) return;
    const taxPercentage = element.dataset.taxMonth;
    let result = 0;
    let formattedResult;

    if (dataType === 'main') {
      [result, formattedResult] = salaryParser.parseSalary(currentSalary, taxPercentage, advancedParams);
    } else {
      result = parseFloat(parseFloat(currentSalary) * parseFloat(taxPercentage) / 100);
      result = salaryParser.normalizeResult(result);
      formattedResult = salaryParser.formatResult(result);
    }
    element.innerText = formattedResult;
    return result;
  };

  if (inputDropdownTogler) {
    inputDropdownTogler.addEventListener('click', () => {
      taxesDropdownContainer.classList.toggle('active');
    });
  }

  const initAdvancedOptionsPanel = container => {
    container.querySelector('.advanced__switch').addEventListener('click', e => {
      e.preventDefault();
      container.classList.toggle('is-active');
    });

    container.querySelectorAll('input[type=checkbox]').forEach(option => {
      option.addEventListener('click', e => {
        Object.keys(advancedParams).forEach(k => {
          if (k !== e.currentTarget.id) {
            advancedParams[k] = false;
            $(`#${k}`).prop('checked', false);
          }
        });
        advancedParams[e.currentTarget.id] = $(e.currentTarget).is(':checked');
        updateTaxSlider();
      });
    });
  };

  if (advancedOptionsContainer) {
    initAdvancedOptionsPanel(advancedOptionsContainer);
  }

  const listener = function() {
    window.requestAnimationFrame(function() {
      updateTaxSlider();
    });
  };

  sliderInput.addEventListener('mousedown', function() {
    listener();
    sliderInput.addEventListener('mousemove', listener);
  });

  sliderInput.addEventListener('mouseup', function() {
    sliderInput.removeEventListener('mousemove', listener);
  });

  sliderInput.addEventListener('input', () => {
    updateTaxSlider();
  });

  for (const dropdownItem of taxesDropdown.children) {
    dropdownItem.addEventListener('click', (event) => {
      document.querySelectorAll('#taxesDropdown > .active')[0].classList.remove('active');
      event.target.classList.add('active');
      const sliderInput = document.getElementById('taxesInput');
      const inputDropdownTogler = document.getElementById('taxRangeText');
      const minVal = JSON.parse(event.target.dataset.minVal);
      const maxVal = JSON.parse(event.target.dataset.maxVal);
      const text = event.target.dataset.dropdownText;
      updataInputMaxMin(minVal, maxVal, text, sliderInput, inputDropdownTogler);
      taxesDropdownContainer.classList.toggle('active');
    });
  }

  updateTaxSlider(sliderInput.value, sliderOutput, taxesDropdown);
};

window.bindBudgetActions = () => {
  const taxBoxes = document.querySelectorAll('.tax-small-box');

  handleTaxRangeSlider(taxBoxes);
  const taxSliderCounter = $('#taxSliderCounter');
  const taxesModal = $('.taxes-modal');
  $(document).on('keyup', function(evt) {
    if (!Boolean(taxesModal.length) && !taxesModal.hasClass('active')) return;
    if (evt.keyCode === 27) {
      taxesModal.removeClass('active');
    }
  });
  $('.taxes-modal__close').on('click', () => {
    taxesModal.removeClass('active');
  });

  $('#taxBoxesSlider').on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
    const i = (currentSlide ? currentSlide : 0) + 1;
    taxSliderCounter.text(i + '/' + slick.slideCount);
  }).slick({
    dots: true,
    infinite: true,
    speed: 400,
    slidesPerRow: 3,
    rows: 2,
    prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzedni slajd"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Następny slajd"></button>',
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesPerRow: 2,
      },
    }],
  });

  $('.taxes-modal-slider').slick({
    fade: true,
    cssEase: 'linear',
    asNavFor: '.taxes-modal-blue-slider',
    prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzednia kategoria"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Następna kategoria"></button>',
  });

  $('.taxes-modal-blue-slider').slick({
    arrows: false,
    fade: true,
    cssEase: 'linear',
    asNavFor: '.taxes-modal-slider',
    prevArrow: '<button type="button" class="slick-prev" aria-label="Poprzednia strona"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Następna strona"></button>',
  });

  for (const taxSingleBox of taxBoxes) {
    const iconEl = taxSingleBox.querySelector('.tax-small-box__image');
    const handleClick = (e) => {
      e.preventDefault();
      const slideno = $(e.target).closest('.tax-small-box').data('slick-box-id');
      $('.taxes-modal-slider').slick('slickGoTo', slideno - 1);
      const modalElement = document.getElementById('taxesModal');
      modalElement.classList.toggle('active');
      setTimeout(() => {
        modalElement.querySelector('.slick-next').focus();
      }, 500);
    };
    iconEl.addEventListener('click', handleClick);
    iconEl.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        handleClick(e);
      }
    });
  }

  $('.taxes-modal-slider-bottom-group').on('click', (e) => {
    if (isMobile.any === false) {
      if ($(e.target).siblings('.taxes-subgroup-tooltip').hasClass('active')) {
        $('.taxes-subgroup-tooltip').each((i, obj) => {
          $(obj).removeClass('active');
          $(obj).parents('.taxes-modal-slider-bottom-group').removeClass('active');
        });
        $('.taxes-modal-slider-bottom-group').each((i, obj) => {
          $(obj).removeClass('active');
        });
      } else {
        $('.taxes-subgroup-tooltip').each((i, obj) => {
          $(obj).removeClass('active');
          $(obj).parents('.taxes-modal-slider-bottom-group').removeClass('active');
        });
        $('.taxes-modal-slider-bottom-group').each((i, obj) => {
          $(obj).removeClass('active');
        });
        if ($(e.target).closest('.taxes-modal-slider-bottom-group.has-tooltip').length === 0) {
          $('#taxesModal.active .taxes-bottom-groups-container').children().each(function() {
            $(this).removeClass('active');
            $(this).find('.taxes-subgroup-tooltip').removeClass('active');
          });
        }
        $(e.target).siblings('.taxes-subgroup-tooltip').addClass('active');
        $(e.target).parents('.taxes-modal-slider-bottom-group').addClass('active');
      }
    }
  });

  // podpiecie popover dla ikonek na mobile
  if (isMobile.any) {
    const budgetTypeIcon = $('.type-budget-boxes__box.has-description .type-budget-boxes__box__image--img');
    budgetTypeIcon.on('click', () => {
      const popover = budgetTypeIcon.parent().find('[data-toggle="popover"]');
      popover.popover('toggle');
    });
  }
};

// window.BudgetSmoothScrollInit = (jquery) => {
//   const autoPlaySlider = jquery('.info-type__slider-info');
//   if (isMobile.phone) {
//     // smoothscroll musi mieć szerokość na stałe więc wyliczam
//     autoPlaySlider.find('.box-invest').width(autoPlaySlider.width());
//   }
//   autoPlaySlider.smoothDivScroll({
//     hotSpotScrolling: false,
//     touchScrolling: true,
//     manualContinuousScrolling: true,
//     mousewheelScrolling: false,
//     autoScrollingMode: 'onStart',
//     autoScrollingPauseOnHover: true,
//     autoScrollingInterval: 22
//   }).find('.box-invest').each(function (index) {
//     if (index % 2) {
//       $(this).addClass('odd');
//     }
//   });
// };

$(document).ready(function() {
  window.budgetInt();
});

// ===============================================//
//   taxesModal uniwersalny                       //
// ===============================================//

$('.see-more--link').click(function() {
  document.getElementById($(this).attr('data-modal')).classList.toggle('active');
});
$('.taxes-modal__close').click(function() {
  $($(this).parent()).removeClass('active');
});

// ===============================================//
//   not precise budget warning modal             //
// ===============================================//

$(document).ready(function() {
  const $notPreciseModal = $('#notPreciseModal');

  if ($notPreciseModal.length) {
    const budgetCookieKey = 'not_precise_' + $notPreciseModal.data('budget');

    if (window.getCookie(budgetCookieKey) === '') {
      $notPreciseModal.addClass('active');
    }

    $('.not-precise-modal__close', $notPreciseModal).on('click', function() {
      $notPreciseModal.removeClass('active');
      window.setCookie(budgetCookieKey, 1, 365 * 10);
    });
  }
});
